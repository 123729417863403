import { Route } from 'universal-router';
import resourceMap from './resourceMap';

type RouteArgs = Omit<Route, 'path'>;

export type SceneId = keyof typeof resourceMap;

const routes: Route[] = [];
let uri: SceneId;
for (uri in resourceMap) {
  const routeArgs = resourceMap[uri];
  routes.push(getRouteObject(uri, routeArgs));
}

function getRouteObject(uri: string, routeArgs: RouteArgs): Route {
  return {
    path: uri,
    ...routeArgs,
  };
}

export type AppState = { [K in SceneId]: any };

export default routes;
