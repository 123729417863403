import { useCallback, useEffect } from 'react';
import { usePixiApp } from 'react-pixi-fiber/index.js';

export default function useAbortablePixiTicker(fn: any, signal?: AbortSignal) {
  const { ticker } = usePixiApp();

  const listener = useCallback(() => {
    ticker.remove(fn);
  }, [fn, ticker]);

  useEffect(() => {
    if (signal && !signal.aborted) {
      signal.addEventListener('abort', listener);
      ticker.add(fn);
    }
    return () => {
      signal && signal.removeEventListener('abort', listener);
      ticker.remove(fn);
    };
  }, [fn, signal, ticker, listener]);
}
