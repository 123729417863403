import { CustomPIXIComponent } from 'react-pixi-fiber/index.js';
import * as PIXI from 'pixi.js';
import { OPTIONS } from '../..';
import { useCallback, useMemo, useRef, useState } from 'react';
import useAbortablePixiTicker from '../../hooks/useAbortablePixiTicker';

const TYPE = 'Curtain';
export const behavior = {
  customDisplayObject: () => new PIXI.Graphics(),
  customApplyProps: (instance: PIXI.Graphics, oldProps: any, newProps: any) => {
    const { fill, x, y, width, height, alpha, zIndex, name } = newProps;

    instance.name = name;
    instance.zIndex = zIndex;
    instance.pivot.x = width / 2;
    instance.pivot.y = height / 2;
    instance.clear();
    instance.beginFill(fill, alpha);
    instance.drawRect(x, y, width, height);
    instance.endFill();
  },
};
const CurtainGraphics = CustomPIXIComponent(behavior, TYPE);

export default function Curtain(props: any) {
  const [alpha, setAlpha] = useState(1.0);
  const _alpha = useRef(1.0);
  const [aborted, setAborted] = useState(false);
  const abortController = useMemo(() => new AbortController(), []);
  const animate = useCallback(
    (delta: number) => {
      const newAlpha = _alpha.current - delta * 0.015;
      if (newAlpha >= 0) {
        _alpha.current = newAlpha;
        setAlpha(newAlpha);
      } else if (!aborted) {
        abortController.abort();
        setAborted(true);
      }
    },
    [abortController, aborted]
  );
  useAbortablePixiTicker(animate, abortController.signal);

  return (
    <CurtainGraphics
      {...props}
      width={OPTIONS.width}
      height={OPTIONS.height}
      fill={0x000000}
      alpha={alpha}
      name="Curtain"
    >
      {props.children}
    </CurtainGraphics>
  );
}
