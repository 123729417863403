import { lazy, Suspense } from 'react';
import Curtain from '../Curtain/Curtain';
import { Text } from 'react-pixi-fiber/index.js';

export default function DynamicallyImportedScene(module: Promise<any>) {
  const SceneComponent = lazy(() => module);
  return (
    <Suspense
      fallback={
        <>
          <Text
            text="Loading..."
            style={{
              dropShadow: true,
              dropShadowAlpha: 0.8,
              fill: '0xffffff',
              fontSize: 54,
            }}
            anchor={{ x: 0.5, y: 0.5 }}
            y={0}
            x={0}
          />
          <Curtain />
        </>
      }
    >
      <SceneComponent />
    </Suspense>
  );
}
