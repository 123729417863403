import { NotFound } from '@curveball/http-errors';
import DynamicallyImportedScene from './DynamicallyImportedScene';

export default {
  '/': {
    action: () => DynamicallyImportedScene(import('../scenes/splash')),
  },
  '/one': {
    action: () => DynamicallyImportedScene(import('../scenes/splash')),
  },
  '/parallax': {
    action: () => DynamicallyImportedScene(import('../scenes/ParallaxScene/ParallaxScene')),
  },
  '/kairens-tree': {
    action: () => DynamicallyImportedScene(import('../scenes/KairensTree/KairensTree')),
  },
  '(.*)': {
    action: () => {
      throw new NotFound('Resource not found');
    },
  },
} as const;
