import React, { useCallback, useEffect, useState } from 'react';
import UniversalRouter, { RouterContext } from 'universal-router';
import { ParallaxCameraProvider } from '../Parallax';
import history from 'history/browser';
import usePersistedReducer, {
  FSAction,
  MigrationManifest,
  State_0,
} from '../../hooks/usePersistedReducer';
import { Draft } from 'immer';
import { HttpError } from '@curveball/http-errors';
import resources, { SceneId } from './resources';

type State_1 = {
  currentSceneId: SceneId;
};
type LatestState = State_1;

const defaultSceneId: SceneId =
  process.env.NODE_ENV === 'production'
    ? '/'
    : (history.location.pathname as SceneId);
const migrations: MigrationManifest = {
  0: (state: State_0) => {
    return {
      currentSceneId: defaultSceneId,
    };
  },
};

const persistedReducer = (
  state: Draft<LatestState>,
  action: FSAction<SceneId>
) => {
  switch (action.type) {
    case 'NAVIGATE_TO_SCENE':
      state.currentSceneId = action.payload;
      break;

    default:
      break;
  }
};

const options = {
  // baseUrl: process.env.PUBLIC_URL,
  errorHandler: (error: Partial<HttpError>, context: RouterContext) => {
    console.error(error.httpStatus, error);
    console.info(context);
    return null;
  },
};
const router = new UniversalRouter(resources, options);

export const NavigationContext = React.createContext<{
  navigate: (sceneId: SceneId) => () => void;
  currentSceneId: SceneId;
}>({
  navigate: () => () => {},
  currentSceneId: defaultSceneId,
});

export default function RouterSwitch() {
  const [locationPathname, setLocationPathname] = useState(defaultSceneId);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const unlisten = history.listen(({ location, action }) => {
        setLocationPathname(location.pathname as SceneId);
      });
      return () => {
        unlisten();
      };
    }
  }, []);

  const { state, dispatch } = usePersistedReducer<
    LatestState,
    FSAction<SceneId>
  >(persistedReducer, migrations, 'ROUTER');

  const [Resource, setResource] = useState<JSX.Element>(<></>);
  useEffect(() => {
    async function resolve() {
      const sceneId = (() => {
        if (process.env.NODE_ENV === 'production') {
          return state.currentSceneId;
        } else {
          return locationPathname === '/'
            ? state.currentSceneId
            : locationPathname;
        }
      })();
      const SceneComponent = await router.resolve(sceneId);
      setResource(SceneComponent!);
    }
    resolve();
  }, [locationPathname, state.currentSceneId]);

  const navigate = useCallback(
    (sceneId: SceneId) => () => {
      dispatch({
        // TODO: Types like this should be a const or enum.
        type: 'NAVIGATE_TO_SCENE',
        payload: sceneId,
      });
    },
    [dispatch]
  );

  return (
    <NavigationContext.Provider
      value={{ navigate, currentSceneId: state.currentSceneId }}
    >
      <ParallaxCameraProvider>{Resource}</ParallaxCameraProvider>
    </NavigationContext.Provider>
  );
}

// TODO: This should only work in Development environment.
export function hardNavigate(sceneId: SceneId) {
  history.push(sceneId);
}
