import React, {
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  Container,
  CustomPIXIComponent,
  usePixiApp,
  usePixiTicker,
} from 'react-pixi-fiber/index.js';
import * as PIXI from 'pixi.js';
import { ParallaxCamera } from '../Parallax/parallax-camera';
import ParallaxLayerContainer from '../Parallax/parallax-camera/ParallaxLayer';

export const ParallaxCameraContext = createContext<ParallaxCamera | null>(null);

export function useParallaxCameraTarget(camera?: ParallaxCamera) {
  const contextCamera = useContext(ParallaxCameraContext);
  const _camera = contextCamera || camera;
  const [cameraTarget, setCameraTarget] = useState<PIXI.DisplayObject>();
  useEffect(() => {
    if (_camera) {
      cameraTarget && _camera.setTarget(cameraTarget);
    }
  }, [_camera, cameraTarget]);

  const _setCameraTarget = useCallback((newTarget?: PIXI.DisplayObject, ...rest: any) => {
    _camera?.setTarget(newTarget);
    setCameraTarget(newTarget);
  }, [_camera])

  return _setCameraTarget;
}

type ParallaxCameraProps = {
  children?: React.ReactNode;
};

export function ParallaxCameraProvider(props: ParallaxCameraProps) {
  const { view, renderer, stage } = usePixiApp();
  const [baseContainer, setBaseContainer] = useState<any>();

  const camera = useMemo(() => {
    if (baseContainer) {
      return new ParallaxCamera(view, renderer, baseContainer, 300, 10);
    } else {
      return null;
    }
  }, [view, renderer, baseContainer]);

  useEffect(() => {
    baseContainer && stage.addChild(baseContainer);
    return () => {
      baseContainer && stage.removeChild(baseContainer);
    };
  }, [stage, camera, baseContainer]);

  const animate = useCallback(
    (delta: number) => {
      // console.log('animate camera  > delta:', delta)
      try {
        camera?.update();
      } catch (error) {
        // console.error('Error updating camera: ', error);
      }
    },
    [camera]
  );
  usePixiTicker(animate);

  return (
    <Container ref={setBaseContainer} {...props}>
      <ParallaxCameraContext.Provider value={camera}>
        {props.children}
      </ParallaxCameraContext.Provider>
    </Container>
  );
}

type ParallaxLayerProps = {
  zIndex?: number;
  children?: React.ReactNode;
};

const CustomParallaxLayer = CustomPIXIComponent(
  {
    customDisplayObject: (props: ParallaxLayerProps) =>
      new ParallaxLayerContainer(props.zIndex),
  },
  'ParallaxLayer'
);

export function ParallaxLayer(props: ParallaxLayerProps) {
  const layerRef = React.useRef<any>(null);
  const camera = useContext(ParallaxCameraContext);
  useEffect(() => {
    const layer = layerRef.current;
    layer.pz = props.zIndex;
    camera?.addLayer(layer);
    return () => {
      camera?.removeLayer(layer);
    };
  }, [camera, props.zIndex, layerRef]);
  return <CustomParallaxLayer ref={layerRef} {...props} />;
}
