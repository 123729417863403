import { current, Immutable } from 'immer';
import { useRef, useEffect, useState } from 'react';
import { useImmerReducer, Reducer } from 'use-immer';
import useCleanupCallback from 'use-cleanup-callback';

export interface FSAction<Payload> {
  type: string;
  payload: Payload;
  error?: boolean;
  meta?: Object;
}

export type State_0 = Immutable<{}>;
const state_0: State_0 = {};

export interface MigrationManifest {
  [key: number]: (s: any) => any;
}

type MetaState = {
  version: number;
  state: any;
};

export default function usePersistedReducer<State, Action>(
  reducer: Reducer<State, Action>,
  migrations: MigrationManifest,
  storageKey: string
) {
  const latestVersion = Object.keys(migrations).length - 1;
  const initialState: State = upgrade(state_0, 0, migrations);
  const [state, dispatch] = useImmerReducer(process.env.NODE_ENV === 'production' ? reducer : loggingReducer, initialState, init);
  const prevState = usePrevious(state);

  // Hydrates state from localStorage.
  function init(): State {
    const stringState = localStorage.getItem(storageKey);
    if (stringState) {
      console.info(storageKey, 'Hydrating persisted state...');
      try {
        const metaState: MetaState = JSON.parse(stringState);
        const { version, state } = metaState;
        console.log(
          storageKey,
          `Hydrated state is version ${version}. Latest is ${latestVersion}.`
        );
        if (version < latestVersion) {
          console.log(storageKey, 'Running migrations... :');
          return upgrade(state, version + 1, migrations);
        } else {
          return state;
        }
      } catch (error) {
        console.error(storageKey, `Persisted state corrupt. Reinitializing...`);
        return initialState;
      }
    } else {
      return initialState;
    }
  }

  // Logger.
  function loggingReducer(...args: any) {
    const [state, action] = args;

    console.group('---- Action Dispatch ----');
    console.info('Previous state:', current(state));

    console.info('Dispatched action:', action);
    reducer.apply(null, args);

    console.info('Next state:', current(state));
    console.groupEnd();
  }

  // Async support.
  const [dispatchingAction, setDispatchingAction] =
    useState<FSAction<any> | void>();
  const customDispatch = useCleanupCallback((action: FSAction<any>) => {
    let isMounted = true;
    const payload = action.payload;
    if (typeof payload?.then === 'function') {
      // If the action payload is a promise:
      const baseActionType = action.type;
      payload.then(
        (value: any) => {
          const action = {
            type: `${baseActionType}/SUCCESS`,
            payload: value,
          };
          isMounted && setDispatchingAction(action);
        },
        (error: any) => {
          const action = {
            type:
              error.type === 'abort'
                ? `${baseActionType}/ABORTED`
                : `${baseActionType}/FAILURE`,
            payload: error,
            error: true,
          };
          isMounted && setDispatchingAction(action);
        }
      );
      const modifiedAction = { ...action, type: `${baseActionType}/PENDING` };
      isMounted && setDispatchingAction(modifiedAction);
    } else {
      isMounted && setDispatchingAction(action);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  // Dispatcher
  useEffect(() => {
    dispatchingAction && dispatch(dispatchingAction as Action);
  }, [dispatch, dispatchingAction]);

  // Persists state to localStorage.
  useEffect(() => {
    // Shallow equal check made possible thanks to Immer.
    const stateEqual = prevState === state;
    if (!stateEqual) {
      console.info('Saving persistent state...');
      const metaState: MetaState = {
        version: latestVersion,
        state,
      };
      const stringifiedState = JSON.stringify(metaState);
      localStorage.setItem(storageKey, stringifiedState);
    }
  }, [state, prevState, storageKey, latestVersion]);

  // Given any value
  // This hook will return the previous value
  // Whenever the current value changes
  function usePrevious(value: State) {
    const ref = useRef<State | undefined>();
    useEffect(() => {
      // This works because the useEffect callback is deferred
      // until after the render.
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  return { state, dispatch: customDispatch };
}

function upgrade(
  accState: any,
  versionIndex: number,
  migrationManifest: MigrationManifest
): any {
  const migrator = migrationManifest[versionIndex];
  if (migrator) {
    return upgrade(migrator(accState), versionIndex + 1, migrationManifest);
  } else {
    return accState;
  }
}
