import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Stage } from 'react-pixi-fiber/index.js';
import RouterSwitch from './components/RouterSwitch/RouterSwitch';
import { PixelateFilter } from './filters/filter-pixelate_v5.0.0';
// import reportWebVitals from './reportWebVitals';

console.info(`Etherion v${process.env.REACT_APP_VERSION}`);

const pixelateFilter = new PixelateFilter(4);

export const OPTIONS = {
  width: 2592,
  height: 1080,
  antialias: true,
};

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Stage options={OPTIONS} filters={[pixelateFilter]}>
    <RouterSwitch />
  </Stage>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
